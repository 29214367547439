import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { Persistor, store } from './redux/store'
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, ThemeProvider } from '@mui/material';
import Router from './router/Router';
import './assets/styles/App.css'

const root = ReactDOM.createRoot(document.getElementById('root'));

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
          <BrowserRouter>
            <ThemeProvider theme={darkTheme}>
                <Router/>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
);
