import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Grid, Input, Skeleton, Zoom } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PaidIcon from '@mui/icons-material/Paid';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Message from './Message';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import chatSvg from './chat.svg'
import showsSvg from './shows.svg'
import { messagesIn } from '../../utils/messages';
import { useRef } from 'react';


function importAll(r) {
    return r.keys().map(r);
}

const avatars = importAll(require.context('../../assets/images/avatars', false, /\.(png|jpe?g|svg)$/));

const user = null;

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

function ChatBlock({ heightMainChat }) {

    var isLoaded = false;

    const [isLoading, setIsLoading] = React.useState(false);

    const [tipsShow, setTipsShow] = React.useState(true);
    const inputMessage = React.useRef();
    const [chatHeight, setChatHeight] = React.useState(null);
    const hasWindow = typeof window !== 'undefined';
    const [tab, setTab] = React.useState('chat');
    const [screenWidth, setScreenWidth] = React.useState();
    const chatContainer = useRef();
    const [messages, setMessages] = React.useState(messagesIn);

    let width;

    const moveChat = () => {
        if (messages?.length > 0) {
            let lastMessage = messages.shift();
            setMessages([...messages, lastMessage]);
            if (isScrolledToBottom()) {
                scrollToBottom();
            }
        }
    }
    let interval;
    let interval2;
    let interval3;

    React.useEffect(() => {
        interval = setInterval(() => {
            if (messages?.length > 0) {
                let lastMessage = messages.shift();
                setMessages([...messages, lastMessage]);
                if (isScrolledToBottom()) {
                    scrollToBottom();
                }
            }
        }, 5759);
        interval2 = setInterval(() => {
            if (messages?.length > 0) {
                let lastMessage = messages.shift();
                setMessages([...messages, lastMessage]);
                if (isScrolledToBottom()) {
                    scrollToBottom();
                }
            }
        }, 7148);

        interval3 = setInterval(() => {
            if (messages?.length > 0) {
                let lastMessage = messages.shift();
                setMessages([...messages, lastMessage]);
                if (isScrolledToBottom()) {
                    scrollToBottom();
                }
            }
        }, 4231);
        // for (let index = 0; index < 1; index++) {
        //     randomInterval(moveChat, 2000, 2500);
        // }
        return () => {
            clearInterval(interval)
            clearInterval(interval2)
            clearInterval(interval3)
        }
    }, [messages]);


    function getDimensions() {
        width = hasWindow ? window.innerWidth : null;
        //height = window.innerHeight;
        if (width < 900 && window.scrollY == 0) {
            setChatHeight(getChatHeight());
            setTipsShow(false);
        }
        if (width)
            setScreenWidth(width);

        scrollToBottom();
    }

    function getChatHeight() {
        var domRect = document.getElementById('main_video') && document.getElementById('main_video').getBoundingClientRect();
        var spaceBelow = window.innerHeight - domRect.bottom;
        return spaceBelow;
    }

    React.useEffect(() => {
        if (heightMainChat) {
            setTimeout(() => {
                getDimensions();
            }, 200);
            setTimeout(() => {
                getDimensions();
            }, 600);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heightMainChat]);

    React.useEffect(() => {
        window.addEventListener("resize", getDimensions);
        if (screenWidth < 900) {
            setTipsShow(false);
        }
        var elms = document.getElementsByClassName('react-input-emoji--button');
        for (let index = 0; index < elms.length; index++) {
            const element = elms[index];
            if (element)
                element.innerHTML = '<span style="width: 24px;height: 24px;display: inline-block;background-image: url(https://unpkg.com/emoji-datasource-apple@5.0.1/img/apple/sheets-256/64.png);background-size: 5700% 5700%;background-position: 53.5714% 62.5%;"/>';
        }
    }, []);

    const isScrolledToBottom = React.useCallback(() => {
        const chatElem = document.getElementById("ChatList");
        if (!chatElem) return;
        if (
            chatElem.scrollHeight - chatElem.scrollTop - 40 <=
            chatElem.clientHeight
        ) {
            return true;
        }
        return false;
    }, []);

    function scrollToBottom() {
        const chatElem = document.getElementById('ChatList');
        if (!chatElem)
            return;
        setTimeout(() => {
            chatElem.scrollTop = chatElem.scrollHeight;
        }, 100);
        setTimeout(() => {
            chatElem.scrollTop = chatElem.scrollHeight;
        }, 200);
        setTimeout(() => {
            chatElem.scrollTop = chatElem.scrollHeight;
        }, 300);
        setTimeout(() => {
            chatElem.scrollTop = chatElem.scrollHeight;
        }, 400);
        setTimeout(() => {
            chatElem.scrollTop = chatElem.scrollHeight;
        }, 500);
    }

    return (
        <Container ref={chatContainer} on={() => moveChat()} className='ChatContainer'>
            <Box className='ChatWrapper' md={3}>
                <>
                    <ButtonGroup variant="contained" sx={{ width: '100%' }} aria-label="outlined primary button group">
                        <Button className={"btn-chat " + (tab === 'chat' ? 'active' : '')}><img alt="Chat" className="leftSvg" src={chatSvg} />Chat</Button>
                        <Button className={"btn-chat " + (tab === 'shows' ? 'active' : '')}><img alt="Shows" className="leftSvg" src={showsSvg} />Shows</Button>
                    </ButtonGroup>
                    {tab === 'chat' ?
                        <>
                            {(heightMainChat > 0) &&
                                <>
                                    <ul className='ChatList' id="ChatList" style={screenWidth < 900 ? { paddingBottom: '70px', maxWidth: `${screenWidth}px !important`, width: '100%', height: chatHeight - 60 + 'px' } : { height: `${heightMainChat - 55}px`, paddingBottom: '100px' }}>
                                        {isLoading ?
                                            [...Array(12)].map((e, i) =>
                                                <Box key={"skel-ch-" + i} sx={{ flexGrow: 1, padding: '4px' }}>
                                                    <Grid container spacing={2} style={
                                                        (i % 3 === 0) ? ({ flexDirection: 'initial' }) :
                                                            ((i % 2 === 0) ? ({ flexDirection: 'row-reverse' }) :
                                                                ((i % 4 === 0) ? ({ flexDirection: 'initial' }) :
                                                                    ({ flexDirection: 'initial' })
                                                                ))
                                                    }>
                                                        <Grid item xs={1.5} style={{ position: 'relative' }}>
                                                            <Skeleton variant="circular" sx={{ bgcolor: 'grey.800' }} width={40} height={40} style={{ bottom: '0', position: 'absolute' }} />
                                                        </Grid>
                                                        <Grid item xs={10} >
                                                            <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.800' }}
                                                                style={
                                                                    (i % 3 === 0) ? ({ margin: "6px", width: '40%', height: '45px', borderRadius: "10px" }) :
                                                                        ((i % 2 === 0) ? ({ margin: "6px", width: '60%', height: '80px', borderRadius: "10px", marginLeft: 'auto' }) :
                                                                            ((i % 4 === 0) ? ({ margin: "6px", width: '100%', height: '75px', borderRadius: "10px" }) :
                                                                                ({ margin: "6px", width: '70%', height: '65px', borderRadius: "10px" })
                                                                            ))
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            )
                                            :
                                            messages?.length > 0 &&
                                            messages.map((message, index) => {
                                                if (isLoaded !== true && messages.length - 1 == index) {
                                                    isLoaded = true
                                                }
                                                return (
                                                    <Message
                                                        key={message.id}
                                                        message={message}
                                                        avatars={avatars}
                                                        isLoaded={isLoaded} />
                                                )
                                            })
                                        }
                                    </ul>

                                    {(!user || (user && user.role !== 'model')) && screenWidth > 900 &&
                                        <Box
                                            sx={{
                                                height: { xs: '40px', md: 'auto' },
                                            }}
                                            style={{
                                                position: 'absolute',
                                                bottom: '60px'
                                            }}
                                            className='tipsBlock'
                                        >
                                            <Zoom in={true}>
                                                <Button className="tipButton" startIcon={<PaidIcon style={{ marginLeft: 5 }} />} endIcon={tipsShow ? <ArrowForwardIosIcon style={{ width: 13, position: 'relative', right: 4 }} /> : <ArrowBackIosNewIcon fontSize="inherit" style={{ width: 13, position: 'relative', right: 4 }} />} variant="contained" size="small"
                                                    style={{ fontSize: '11px', marginRight: '3px', color: '#FFFFFF', backgroundColor: '#A725F9', maxWidth: 70, minWidth: 70, padding: 3 }}>
                                                    TIP
                                                </Button>
                                            </Zoom>
                                            <ButtonGroup size="small" aria-label="small" className='tipsBlock tipsBlock_buttons' style={{ justifyContent: 'flex-start', flexWrap: 'wrap', color: '#FFFFFF', }}>
                                                <Zoom in={tipsShow} style={{ transitionDelay: 25 + 'ms' }}><Button className="tipButton">10</Button></Zoom>
                                                <Zoom in={tipsShow} style={{ transitionDelay: 50 + 'ms' }}><Button className="tipButton">25</Button></Zoom>
                                                <Zoom in={tipsShow} style={{ transitionDelay: 75 + 'ms' }}><Button className="tipButton">100</Button></Zoom>
                                                <Zoom in={tipsShow} style={{ transitionDelay: 100 + 'ms' }}><Button className="tipButton">250</Button></Zoom>
                                                <Zoom in={tipsShow} style={{ transitionDelay: 125 + 'ms' }}>
                                                    <Button
                                                        className="tipButton"
                                                        style={{
                                                            fontSize: '11px',
                                                            backgroundColor: '#A725F9',
                                                            color: '#FFFFFF',
                                                            maxWidth: 30,
                                                            minWidth: 30,
                                                            padding: 4,
                                                            marginRight: 4
                                                        }}
                                                    >
                                                        <ModeEditOutlineIcon sx={{ fontSize: 20 }} />
                                                    </Button>
                                                </Zoom>
                                            </ButtonGroup>
                                        </Box>
                                    }
                                    <form style={{ backgroundColor: '#ffffff', display: 'flex', alignItems: 'center', position: 'absolute', bottom: '0', left: '0', right: '0' }}>
                                        <>
                                            {screenWidth < 900 &&
                                                <Box
                                                    className='tipsBlock'
                                                    style={{ position: 'absolute', zIndex: 5, marginLeft: '8px' }}
                                                >
                                                    {
                                                        tipsShow ? (
                                                            <Zoom in={tipsShow}>
                                                                <IconButton variant="contained" size="small" aria-label="delete" style={{ marginRight: '3px', color: '#FFFFFF', backgroundColor: '#A725F9', borderRadius: '5px', maxWidth: 30, minWidth: 30, padding: 6 }}>
                                                                    <ArrowBackIosNewIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Zoom>
                                                        ) : (
                                                            <Zoom in={!tipsShow}>
                                                                <Button startIcon={<PaidIcon />} endIcon={<ArrowForwardIosIcon />} variant="contained" size="small"
                                                                    style={{ fontSize: '11px', marginRight: '3px', color: '#FFFFFF', backgroundColor: '#A725F9', maxWidth: 70, minWidth: 70, padding: 5 }}>
                                                                    TIP
                                                                </Button>
                                                            </Zoom>
                                                        )
                                                    }
                                                    <ButtonGroup size="small" aria-label="small" className='tipsBlock tipsBlock_buttons' style={{ justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', color: '#FFFFFF', backgroundColor: 'rgb(255, 255, 255)', display: 'none' }}>
                                                        <Zoom in={tipsShow} style={{ transitionDelay: 25 + 'ms' }}><Button style={{ fontSize: '11px', backgroundColor: '#A725F9', color: '#FFFFFF', maxWidth: 33, minWidth: 33, marginRight: 4, padding: 4 }}>10</Button></Zoom>
                                                        <Zoom in={tipsShow} style={{ transitionDelay: 50 + 'ms' }}><Button style={{ fontSize: '11px', backgroundColor: '#A725F9', color: '#FFFFFF', maxWidth: 33, minWidth: 33, marginRight: 4, padding: 4 }}>25</Button></Zoom>
                                                        <Zoom in={tipsShow} style={{ transitionDelay: 75 + 'ms' }}><Button style={{ fontSize: '11px', backgroundColor: '#A725F9', color: '#FFFFFF', maxWidth: 33, minWidth: 33, marginRight: 4, padding: 4 }}>100</Button></Zoom>
                                                        <Zoom in={tipsShow} style={{ transitionDelay: 100 + 'ms' }}><Button style={{ fontSize: '11px', backgroundColor: '#A725F9', color: '#FFFFFF', maxWidth: 33, minWidth: 33, marginRight: 4, padding: 4 }}>250</Button></Zoom>
                                                        <Zoom in={tipsShow} style={{ transitionDelay: 125 + 'ms' }}>
                                                            <Button style={{ fontSize: '11px', backgroundColor: '#A725F9', color: '#FFFFFF', maxWidth: 30, minWidth: 30, marginRight: 7, padding: 4 }}>
                                                                <ModeEditOutlineIcon sx={{ fontSize: 20 }} />
                                                            </Button>
                                                        </Zoom>
                                                    </ButtonGroup>
                                                </Box>
                                            }
                                            <Input
                                                type="text"
                                                disableUnderline
                                                inputRef={inputMessage}
                                                cleanOnEnter
                                                maxLength={255}
                                                className={screenWidth > 900 ? "ChatInput" : "ChatInput mobile"}
                                                autoFocus={true}
                                                endAdornment={
                                                    <svg style={{ fill: 'rgb(116 116 116)' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="react-input-emoji--button--icon"><path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0m0 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"></path><path d="M8 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 7M16 7a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 7M15.232 15c-.693 1.195-1.87 2-3.349 2-1.477 0-2.655-.805-3.347-2H15m3-2H6a6 6 0 1 0 12 0"></path></svg>
                                                }
                                                placeholder="Type your message here..."
                                                style={{ fontSize: '14px', color: '#282828', padding: '11px 20px', width: '100%' }}
                                            />
                                        </>
                                    </form>
                                </>
                            }
                        </>
                        :
                        <></>
                    }
                </>
            </Box>
        </Container>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBlock);