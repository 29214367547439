export const messagesIn = [
    {
        "id": 27076,
        "message": "Test",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27077,
        "message": "hi",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27078,
        "message": "🥰Come with me baby",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670264755&signature=42ec6a81dc2e56fd2e16dc77b8b0cc4c299437bc05aaf446ba1609cbe27710d0",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27081,
        "message": "Valeria Fuentes's lush was activated for 50 Credits",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Catfish",
            "userId": 2129,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2038",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27083,
        "message": "thks u baby",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670265614&signature=1ffd45e380d9b20f8d17371a3ba2630fce878a209bf5a13df2dfae0bbfca0b6c",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27084,
        "message": "Hey hey",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 073472",
            "userId": "073472",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27085,
        "message": "Valeria Fuentes got tipped 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2038",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27087,
        "message": "Hey",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670265838&signature=961938966d9808f48dc5e41ee6e6a8b5475984ddd17631b2258e65bb54e4087e",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27089,
        "message": "Hi bb",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670265935&signature=02bd7bd8a7e79ef0f6dcb8b17115c5fbfaefa43ebee714a3ccdbe885ba26e604",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27090,
        "message": "love....do you want you see my squirting.....",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670266065&signature=5a2d5aa1b71838fb31f7b9cc28daef24c14b68535d793e8214166b0edb57813a",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27089
    },
    {
        "id": 27091,
        "message": "active my lush bb",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670266085&signature=30b50476ac564bd9f4cb5d4d25244ee407350e9a7dd05ab302a7a850be5b62ec",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27089
    },
    {
        "id": 27092,
        "message": "Valeria Fuentes got tipped 50",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Catfish",
            "userId": 2129,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2038",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27096,
        "message": "hola",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1896-400x300.jpeg",
            "name": "Fancyfanc",
            "userId": 191,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27097,
        "message": "whats going on",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1896-400x300.jpeg",
            "name": "Fancyfanc",
            "userId": 191,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27096
    },
    {
        "id": 27098,
        "message": "Fent",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Catfish",
            "userId": 2129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27099,
        "message": "Amazing show",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670267046&signature=10ae9f358bed4b18369564b5606e3c18f08eec6bd0b79b96613ddefcbef7c91f",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27100,
        "message": "😀",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670267093&signature=164ba72a5528255224c99f59f2ce4460b1493a6fae11f6941d55d6162a78de06",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27099
    },
    {
        "id": 27101,
        "message": "hey sweetie",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27102,
        "message": "hey",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27101
    },
    {
        "id": 27103,
        "message": "hy",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27101
    },
    {
        "id": 27104,
        "message": "Hey",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 779278",
            "userId": "779278",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27105,
        "message": "hi",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27106,
        "message": "Hi loves",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670267543&signature=b3c39415d7fa48c8d2ef75632fb4b675e16b1396c01fcf0f6e32a9132fa4f47b",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27107,
        "message": "hey",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 617749",
            "userId": "617749",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27108,
        "message": "My show in camroom 4",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670267595&signature=1a5f5973eb84f36f53a83dc712927c39de90a83e2d34bb5a7adca8c5b22581af",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27109,
        "message": "Omg Valeria is so hot!!",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670267889&signature=578c19a2042c6a34f9b7c809bc0ff9e11dea96ed7ed0fcd651bcb4a050619540",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27110,
        "message": "Cam room 4😍",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670267909&signature=ec95961829b9522919f783c1f992332935f2fca7f608636fee8ba010a5cb25e5",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27109
    },
    {
        "id": 27111,
        "message": "She is very hot valeria",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Catfish",
            "userId": 2129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27112,
        "message": "absolutly beautiful girls",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/09/19/image-medium-259-400x300.jpeg",
            "name": "trent",
            "userId": 388,
            "role": "member",
            "is_premium": 1,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27113,
        "message": "well well well",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1896-400x300.jpeg",
            "name": "Fancyfanc",
            "userId": 191,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27119,
        "message": "mmm thats eatable",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/09/19/image-medium-259-400x300.jpeg",
            "name": "trent",
            "userId": 388,
            "role": "member",
            "is_premium": 1,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27121,
        "message": "Valeria in Liveshow woow",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1896-400x300.jpeg",
            "name": "Fancyfanc",
            "userId": 191,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27125,
        "message": "someone needs a spanking",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/09/19/image-medium-259-400x300.jpeg",
            "name": "trent",
            "userId": 388,
            "role": "member",
            "is_premium": 1,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27126,
        "message": "Ooooh yes",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/22/original-0d984e820c-8111rs.webp?expires=1670269042&signature=2dbb24ec9615e197cfea8d2dfc7d175711c03fcae4098edf3db22f85e3e3eba1",
            "name": "Kitty Azora",
            "userId": 460,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27127,
        "message": "jealous of kitty right now",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/09/19/image-medium-259-400x300.jpeg",
            "name": "trent",
            "userId": 388,
            "role": "member",
            "is_premium": 1,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27129,
        "message": "how do i get to valeria in live show",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27130,
        "message": "Älos",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27129
    },
    {
        "id": 27131,
        "message": "https://warehouse-x.io/liveshows/198",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1896-400x300.jpeg",
            "name": "Fancyfanc",
            "userId": 191,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27132,
        "message": "Choose cam room 4, scroll down to find them",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/22/original-0d984e820c-8111rs.webp?expires=1670269266&signature=70590190704415630ee749933a6c8f253f2e288e24f9fc8b320d933e4b086ccf",
            "name": "Kitty Azora",
            "userId": 460,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27134,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27135,
        "message": "fill it up",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27134
    },
    {
        "id": 27139,
        "message": "😍",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fail",
            "userId": 1129,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27135
    },
    {
        "id": 27141,
        "message": "Noa Tevez's lush was activated for 100 Credits",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Stankfest",
            "userId": 1107,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "1994",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27146,
        "message": "Play with us ;) we’re ready",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670269838&signature=47bf98f1af7c1f17848fb8ebb49e383040d470f995880baa8507bc7f0d3f2337",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27147,
        "message": "Belle Jolie got tipped 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fistwerk",
            "userId": 1258,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2070",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27148,
        "message": "nice fanny noa",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Fistwerk",
            "userId": 1258,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27147
    },
    {
        "id": 27149,
        "message": "Valeria Fuentes got tipped 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Catfish",
            "userId": 2129,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2038",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27151,
        "message": "Who got lush?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27152,
        "message": "I cant see it",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27151
    },
    {
        "id": 27153,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27154,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27155,
        "message": "Thank you xx",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670270457&signature=57c17699711c5a4f9eb3dee6a3229f386ff39ce0ec111c456b4f0f1ae17de771",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27156,
        "message": "Belle Jolie got tipped 200",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2070",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27157,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27158,
        "message": "Lush party?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670270490&signature=1886d9ec0dd30f1233d88b3be22e26a0d030f0394d10a80998aa9e869bf619a9",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27159,
        "message": "🕹️",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670270504&signature=412dd0d6e6efbea419404638a321004589646ea7693571083d3d05cb8e64e440",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27158
    },
    {
        "id": 27160,
        "message": "Valeria Fuentes's lush was activated for 50 Credits",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670270763&signature=68bffd12c3fd63ddd6124c296f4ca8e9f379a520cc3e56da8a4465d4f7a2678c",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2038",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27161,
        "message": "Valeria Fuentes's lush was activated for 100 Credits",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2038",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27162,
        "message": "Group tip 250",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27163,
        "message": "Group tip 250",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27162
    },
    {
        "id": 27164,
        "message": "Group tip 250",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27162
    },
    {
        "id": 27165,
        "message": "Group tip 250",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27162
    },
    {
        "id": 27166,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27167,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27166
    },
    {
        "id": 27168,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27166
    },
    {
        "id": 27169,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27166
    },
    {
        "id": 27170,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27166
    },
    {
        "id": 27171,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27172,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27171
    },
    {
        "id": 27173,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MapleSyrup",
            "userId": 1056,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27174,
        "message": "Intrigued",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27175,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "MannyPeddy",
            "userId": 2131,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27174
    },
    {
        "id": 27176,
        "message": "Hola",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 886538",
            "userId": "886538",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27177,
        "message": "Hola amor",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670271885&signature=16b06ee48ca7c52b2fa89284a44cd4b02f087f95de8e352ebc92db933acf9c8f",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27178,
        "message": "holaa",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/12/image-medium-682-400x300.png",
            "name": "user69",
            "userId": 171,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27179,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/12/image-medium-682-400x300.png",
            "name": "user69",
            "userId": 171,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27178
    },
    {
        "id": 27180,
        "message": "hola amores :3",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27181,
        "message": "bien ¿y vosotras musas?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27182,
        "message": "😘",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27183,
        "message": "estoy solo, mola el verano jeje",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27184,
        "message": "delicias sois muy lindas",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27185,
        "message": "puedes acertartelo mas amor",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27186,
        "message": "para no salirte del yacuzzy",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27187,
        "message": "no quiero que cada vez que hable salgas del yacuzzy",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27188,
        "message": "bombom",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27189,
        "message": ":3",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27180
    },
    {
        "id": 27190,
        "message": ":)",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/24/original-ce7c9b2ec4-nora.webp?expires=1670276885&signature=a86f01ff4fbb8d31ab33b56b4f6c9e41dc32e1f7f678425363be66ba2442d6b6",
            "name": "Noa Tevez",
            "userId": 1994,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27191,
        "message": "la rubia baila muy rico",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27192,
        "message": "si tuviera tokens os daria 250 para pasarlo rico streapers",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27191
    },
    {
        "id": 27193,
        "message": "soys unas musas",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "StreamBoy69",
            "userId": 2135,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27191
    },
    {
        "id": 27194,
        "message": "hello all",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/FedSex.svg",
            "name": "JEYX",
            "userId": 1475,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27195,
        "message": "hi",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27196,
        "message": "Hola",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 979884",
            "userId": "979884",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27197,
        "message": "😘",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 979884",
            "userId": "979884",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27196
    },
    {
        "id": 27211,
        "message": "Group tip 250",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27212,
        "message": "panties off",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27211
    },
    {
        "id": 27213,
        "message": "cmon",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27212
    },
    {
        "id": 27214,
        "message": "and show me nipples Bby",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27212
    },
    {
        "id": 27215,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27216,
        "message": "Thank you baby xx",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670281175&signature=e887eb2258d107a1caae16480d97e0d17368c18a0ce980193c70cd92d93e9a6f",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27217,
        "message": "rub those nipples",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27218,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27217
    },
    {
        "id": 27219,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27218
    },
    {
        "id": 27220,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27218
    },
    {
        "id": 27221,
        "message": "and now",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27222,
        "message": "only 1 girl will get reward",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27223,
        "message": "game like we played before",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27224,
        "message": "the fastest twerk",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27225,
        "message": "who will twerk faster will get a reward",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27226,
        "message": "lets do that on table",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27227,
        "message": "i see only 1 girl Twerking",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27228,
        "message": "everyone get on a table",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27229,
        "message": "then we will start competiotion",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27230,
        "message": "who s the girl on a table ?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27231,
        "message": "oh. all are",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27232,
        "message": "good good",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27233,
        "message": "almost there",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27234,
        "message": "right now the blond girl is winning",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27235,
        "message": "everyone have a chance",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27236,
        "message": "very good. Seems like i cant choose between you",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27237,
        "message": "seems like you all won",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27221
    },
    {
        "id": 27238,
        "message": "Group tip 1000",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27239,
        "message": "yessss",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27238
    },
    {
        "id": 27240,
        "message": "asses",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27239
    },
    {
        "id": 27241,
        "message": "love the asses",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27239
    },
    {
        "id": 27242,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27243,
        "message": "i will spend all my money here",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false,
        "previousId": 27242
    },
    {
        "id": 27244,
        "message": "damn",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27243
    },
    {
        "id": 27245,
        "message": "love the conrol",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27243
    },
    {
        "id": 27246,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27247,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27246
    },
    {
        "id": 27248,
        "message": "rub each other nipples",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27249,
        "message": "cmon",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27248
    },
    {
        "id": 27250,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27251,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27250
    },
    {
        "id": 27252,
        "message": "oh god",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27253,
        "message": "is that oat milk?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27252
    },
    {
        "id": 27254,
        "message": "very good. My fav one",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27252
    },
    {
        "id": 27255,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": false
    },
    {
        "id": 27256,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27255
    },
    {
        "id": 27257,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27255
    },
    {
        "id": 27258,
        "message": "Group tip 50",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27255
    },
    {
        "id": 27259,
        "message": "Group tip 25",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/MrBig.svg",
            "name": "GoldenShitter",
            "userId": 2069,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27255
    },
    {
        "id": 27261,
        "message": "Belle Shower time!",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670282676&signature=7887a2f35a9776c24bebd601b88e1bab427317e61869b336e5812d05375fb314",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27262,
        "message": "The girls are in bedroom ;)",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670282690&signature=64daf556a9f92001b24f4ae35237d49bd319d7cdd1153ab2f9a7af8b3f7c5718",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27261
    },
    {
        "id": 27263,
        "message": "Belle and Munequita in shower! Come see us ;)",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670283081&signature=69e128526d84673ccd740eeb958661518ee9572f5c2a282b2bf8224828af35fb",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27261
    },
    {
        "id": 27264,
        "message": "And Noa!! Omg come check the shower",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670283121&signature=fc20709769aea6c9a45845c216fd071ab59ac7407da8e1efe9e701cc4a722093",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27261
    },
    {
        "id": 27265,
        "message": "Mmm",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Deivid21",
            "userId": 2140,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27266,
        "message": "You like?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670286336&signature=8ee6cf6ce83992578936b5dc177997c78a2fcd6dd88d650c9314dd17157c5946",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27267,
        "message": "Good morning ❤️",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/28/original-0dd25e2fed-belle2.webp?expires=1670318088&signature=2dd2fa436889feafd1d9a71c7b3cfd585bd2b40e78c8614b79be0cf48accec2b",
            "name": "Belle Jolie",
            "userId": 2070,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27266
    },
    {
        "id": 27268,
        "message": "Hi ♥️",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/25/original-780d4b2715-valeria.webp?expires=1670323965&signature=320d006afbb219380c243e6ae094ad89e4532e19a785ed6ccee0f9150d27916e",
            "name": "Valeria Fuentes",
            "userId": 2038,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27269,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "PooPusher",
            "userId": 2126,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27271,
        "message": "Tipped for show 250",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "PooPusher",
            "userId": 2126,
            "role": "member",
            "is_pool": true,
            "is_premium": 0,
            "pool_id": 119,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27269
    },
    {
        "id": 27286,
        "message": "hi",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "Guest 169231",
            "userId": "169231",
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27288,
        "message": "hiiii",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/22/original-0d984e820c-8111rs.webp?expires=1670332831&signature=2975b781268d9526088c78d45118a8cc1ad9e7edd031f3933772ea5606ccbb33",
            "name": "Kitty Azora",
            "userId": 460,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27326,
        "message": "Kitty Azora got tipped 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670333660&signature=31ea491580581c630e8c645a831919aaf0f6300c191710101f94d9db7ba17d41",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "460",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27349,
        "message": "Kitty Azora's lush was activated for 50 Credits",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/12/image-medium-682-400x300.png",
            "name": "user69",
            "userId": 171,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "460",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27360,
        "message": "Kitty Azora got tipped 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/avatars/CockMonger.svg",
            "name": "craig",
            "userId": 2146,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "460",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27367,
        "message": "Kitty, connect lush please",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670334989&signature=3c03962fd1d21590c4f9f0b590a654b0fd51c790358388b7c31fd3a852e3da50",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27368,
        "message": "dooone",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/22/original-0d984e820c-8111rs.webp?expires=1670335134&signature=8e72b2fd682a0019695ec847e19cb56fd6dd281c3e89a75daea4fc042407baf6",
            "name": "Kitty Azora",
            "userId": 460,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27369,
        "message": "Kitty Azora's lush was activated for 50 Credits",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670335164&signature=b1794a1804e79570d1ab23e98d736d1c80417b5859fed2fc78842c7871b40db5",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "460",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27370,
        "message": "paaaaarty",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/22/original-0d984e820c-8111rs.webp?expires=1670335178&signature=163bb2f3959e137b7f142831e4e51ff77c66c4448e401b53496cfadb932318ee",
            "name": "Kitty Azora",
            "userId": 460,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27371,
        "message": "👍",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/01/original-055ef3730a-1775798png.png?expires=1670335181&signature=33eda8cc4bcbac006aedc2011d7e40ee6c52e343209a01362c8689fb31475b99",
            "name": "MagomedGadzijev",
            "userId": 871,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27415,
        "message": "hi ladies",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27416,
        "message": "🍉",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27415
    },
    {
        "id": 27417,
        "message": "who would like some watermelon?",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27415
    },
    {
        "id": 27419,
        "message": "like an after dinner mint",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27415
    },
    {
        "id": 27421,
        "message": "like an ass dinner mint",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27415
    },
    {
        "id": 27423,
        "message": "before and after",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/22/original-0d984e820c-8111rs.webp?expires=1670336812&signature=f1779b41bd6b04090b7dfcdaed0903a1f8ca697cd2d450f5144279fcd6a0dc6d",
            "name": "Kitty Azora",
            "userId": 460,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27456,
        "message": "Belle Jolie got tipped 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/12/image-medium-682-400x300.png",
            "name": "user69",
            "userId": 171,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": "2070",
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27457,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27460,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27457
    },
    {
        "id": 27461,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27457
    },
    {
        "id": 27462,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/12/image-medium-682-400x300.png",
            "name": "user69",
            "userId": 171,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27465,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27466,
        "message": "i go jacuzzy :P",
        "type": "text",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/api/v1/images/imageMedium/uploads/models/2022/11/24/original-ce7c9b2ec4-nora.webp?expires=1670343089&signature=a20dcc4b01c5534a53d2f06236aa5b0982ac0272f16f6ba025c5ae77d47ad306",
            "name": "Noa Tevez",
            "userId": 1994,
            "role": "model",
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27471,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27472,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": true,
        "previousSameType": true,
        "previousId": 27471
    },
    {
        "id": 27473,
        "message": "Group tip 100",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "",
            "name": "ieatassalldaylongassholes",
            "userId": 2147,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    },
    {
        "id": 27474,
        "message": "Group tip 10",
        "type": "tip",
        "banned": true,
        "data": {
            "avatar": "https://whxlb-fr.warehouse-x.io/uploads/models/2022/10/13/image-medium-1895-400x300.png",
            "name": "HasanGadzijev15",
            "userId": 504,
            "role": "member",
            "is_pool": false,
            "is_premium": 0,
            "pool_id": 0,
            "event": "",
            "url": "",
            "actionType": null
        },
        "isPrevious": false
    }
]