import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import Logo from "../../assets/images/warehouse-x-logo.png";

const Header = () => {


  return (
    <AppBar position="static" className="navBar" id="navBar">
      <Container maxWidth="xxl">
        <Toolbar className="navBarBlock" sx={{ display: "flex", justifyContent: 'space-between' }}>

          {/* normal */}
          <Box
            sx={{
              // mr: 1,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
              height: "55px",
              position: 'relative',
              bottom: '10px'
            }}
          >
            <Avatar
              sx={{
                display: { xs: "none", md: "flex" },
                width: "260px",
                height: "fit-content",
              }}
              variant="square"
              src={Logo}
            />
          </Box>

          {/* mobile */}
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Avatar
              sx={{
                display: { xs: "flex", md: "none" },
                mr: 0.5,
                width: "200px",
                height: 'fit-content'
              }}
              variant="square"
              src={Logo}
            />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip disableHoverListener title={""}>
              <Stack direction="row" spacing={1}>
                
              </Stack>
            </Tooltip>
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
