import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import imagePlaceholder from "../../assets/images/imagePlaceholder.png";
import { connect } from "react-redux";
import { useRef } from "react";
const mapStateToProps = state => {
  return {
    
  }
}
function RoomCard({ camera2 }) {
  const cameraRef = useRef();

  const [imageLoaded, setImageLoaded] = React.useState(false);

  return (
    <Card ref={cameraRef} className="CameraCard" style={camera2.id === 410 ? { border: "3px solid #77F73B", borderRadius: " 5px 5px 5px 5px" } : { border: '3px solid transparent' }}>
      <CardActionArea>
        <div className="smooth-image-wrapper">
          <CardMedia
            component="img"
            height="80"
            image={
              camera2 && camera2.thumbnail_url
                ? camera2.thumbnail_url
                : imagePlaceholder
            }
            loading="lazy"
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <CardContent style={{ padding: "10px", margin: "0" }}>
          <Typography gutterBottom variant="h8" component="span">
            {camera2 && camera2.camera_name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
export default connect(mapStateToProps)(RoomCard)