import * as React from 'react';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import RoomCard from '../RoomCard/RoomCard';
import { createSvgIcon } from '@mui/material/utils';
import { Box } from '@mui/system';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { Skeleton } from '@mui/material';
import styled from './ScrollSection.module.css'

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('../../assets/images/cams', false, /\.(webp|jpe?g|svg)$/));

const cameras = [
    {
        "id": 410,
        "room_name": "Live Show Room",
        "camera_name": "Live Show Room",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 1,
        "online_time": null,
        "thumbnail_url": images[26],
        "cover_url": images[26],
        "participants": []
    },
    {
        "id": 411,
        "room_name": "Jacuzzi 2",
        "camera_name": "Jacuzzi 2",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[19],
        "cover_url": images[19],
        "participants": []
    },
    {
        "id": 412,
        "room_name": "Jacuzzi 3",
        "camera_name": "Jacuzzi 3",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[20],
        "cover_url": images[20],
        "participants": []
    },
    {
        "id": 405,
        "room_name": "Jacuzzi 4",
        "camera_name": "Jacuzzi 4",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[21],
        "cover_url": images[21],
        "participants": []
    },
    {
        "id": 403,
        "room_name": "Dining Table",
        "camera_name": "Dining Table",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[15],
        "cover_url": images[15],
        "participants": []
    },
    {
        "id": 407,
        "room_name": "Gym 1",
        "camera_name": "Gym 1",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[16],
        "cover_url": images[16],
        "participants": []
    },
    {
        "id": 437,
        "room_name": "Gym 2",
        "camera_name": "Gym 2",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[17],
        "cover_url": images[17],
        "participants": []
    },
    {
        "id": 445,
        "room_name": "Gym 3",
        "camera_name": "Gym 3",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[18],
        "cover_url": images[18],
        "participants": []
    },
    {
        "id": 413,
        "room_name": "Sun Lounge",
        "camera_name": "Sun Lounge",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[36],
        "cover_url": images[36],
        "participants": []
    },
    {
        "id": 438,
        "room_name": "Ping-Pong",
        "camera_name": "Ping-Pong",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[30],
        "cover_url": images[30],
        "participants": []
    },
    {
        "id": 409,
        "room_name": "Billiard",
        "camera_name": "Billiard",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[12],
        "cover_url": images[12],
        "participants": []
    },
    {
        "id": 401,
        "room_name": "Kitchen 1",
        "camera_name": "Kitchen 1",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[22],
        "cover_url": images[22],
        "participants": []
    },
    {
        "id": 402,
        "room_name": "Kitchen 2",
        "camera_name": "Kitchen 2",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[23],
        "cover_url": images[23],
        "participants": []
    },
    {
        "id": 414,
        "room_name": "Kitchen 3",
        "camera_name": "Kitchen 3",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[24],
        "cover_url": images[24],
        "participants": []
    },
    {
        "id": 441,
        "room_name": "Bathroom 2",
        "camera_name": "Bathroom 2",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[1],
        "cover_url": images[1],
        "participants": []
    },
    {
        "id": 440,
        "room_name": "Bathroom",
        "camera_name": "Bathroom",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[0],
        "cover_url": images[0],
        "participants": []
    },
    {
        "id": 446,
        "room_name": "Shower",
        "camera_name": "Shower",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[35],
        "cover_url": images[35],
        "participants": []
    },
    {
        "id": 415,
        "room_name": "Cinema",
        "camera_name": "Cinema",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[14],
        "cover_url": images[14],
        "participants": []
    },
    {
        "id": 439,
        "room_name": "Quiet Zone",
        "camera_name": "Quiet Zone",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[31],
        "cover_url": images[31],
        "participants": []
    },
    {
        "id": 435,
        "room_name": "Salon 2",
        "camera_name": "Salon 2",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[33],
        "cover_url": images[33],
        "participants": []
    },
    {
        "id": 436,
        "room_name": "Salon",
        "camera_name": "Salon",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[32],
        "cover_url": images[32],
        "participants": []
    },
    {
        "id": 406,
        "room_name": "Chess",
        "camera_name": "Chess",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[13],
        "cover_url": images[13],
        "participants": []
    },
    {
        "id": 408,
        "room_name": "Scenic",
        "camera_name": "Scenic",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[34],
        "cover_url": images[34],
        "participants": []
    },
    {
        "id": 404,
        "room_name": "Lounge",
        "camera_name": "Lounge",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[27],
        "cover_url": images[27],
        "participants": []
    },
    {
        "id": 426,
        "room_name": "Munequita bed",
        "camera_name": "Munequita bed",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[28],
        "cover_url": images[28],
        "participants": []
    },
    {
        "id": 417,
        "room_name": "bed 103",
        "camera_name": "bed 103",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[3],
        "cover_url": images[3],
        "participants": []
    },
    {
        "id": 420,
        "room_name": "Kitty's bed",
        "camera_name": "Kitty's bed",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[25],
        "cover_url": images[25],
        "participants": []
    },
    {
        "id": 421,
        "room_name": "Belle Jolie bed",
        "camera_name": "Belle Jolie bed",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[11],
        "cover_url": images[11],
        "participants": []
    },
    {
        "id": 424,
        "room_name": "NoaTerez Bed",
        "camera_name": "NoaTerez Bed",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[29],
        "cover_url": images[29],
        "participants": []
    },
    {
        "id": 425,
        "room_name": "Valeria Fuentas bed",
        "camera_name": "Valeria Fuentas bed",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[37],
        "cover_url": images[37],
        "participants": []
    },
    {
        "id": 418,
        "room_name": "Bed 201",
        "camera_name": "Bed 201",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[5],
        "cover_url": images[5],
        "participants": []
    },
    {
        "id": 433,
        "room_name": "Bed  202",
        "camera_name": "Bed  202",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[6],
        "cover_url": images[6],
        "participants": []
    },
    {
        "id": 431,
        "room_name": "Bed 203",
        "camera_name": "Bed 203",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[6],
        "cover_url": images[6],
        "participants": []
    },
    {
        "id": 432,
        "room_name": "Bed 204",
        "camera_name": "Bed 204",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[7],
        "cover_url": images[7],
        "participants": []
    },
    {
        "id": 427,
        "room_name": "Bed 102",
        "camera_name": "Bed 102",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[2],
        "cover_url": images[2],
        "participants": []
    },
    {
        "id": 422,
        "room_name": "bed 104",
        "camera_name": "bed 104",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[4],
        "cover_url": images[4],
        "participants": []
    },
    {
        "id": 429,
        "room_name": "Bed 206",
        "camera_name": "Bed 206",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[9],
        "cover_url": images[9],
        "participants": []
    },
    {
        "id": 430,
        "room_name": "Bed 207",
        "camera_name": "Bed 207",
        "camera_type": "common",
        "hls_url": null,
        "last_online": null,
        "freeUnlocked": 0,
        "online_time": null,
        "thumbnail_url": images[10],
        "cover_url": images[10],
        "participants": []
    }
]

const HomeIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="15.291" viewBox="0 0 22.001 15.291">
        <g id="Group_56" data-name="Group 56" transform="translate(0.1 0.123)">
            <path id="Path_48" data-name="Path 48" d="M4.362,8.275a1.692,1.692,0,0,0,.618,2.212.1.1,0,0,1,.055.088q0,1.351,0,2.7c0,.011,0,.023,0,.044H-.1V15.04h.075q2.52,0,5.039,0a1.749,1.749,0,0,0,1.114-.366,1.546,1.546,0,0,0,.569-.936,2.325,2.325,0,0,0,.044-.453q.006-1.344,0-2.689a.113.113,0,0,1,.061-.113,1.671,1.671,0,0,0,.665-.735c.054.024.106.045.158.071q1.114.538,2.226,1.074l2.7,1.3,1.41.679a1.035,1.035,0,0,0,1.087-.158,1.194,1.194,0,0,0,.281-.383q.716-1.52,1.434-3.04c.257-.543.511-1.088.769-1.632a.952.952,0,0,0-.463-1.273c-.157-.062-.3-.143-.457-.215l-2.947-1.4L11.7,3.837,8.821,2.469,6.564,1.4Q5.124.712,3.686.029A1.154,1.154,0,0,0,3.08-.12.885.885,0,0,0,2.311.4c-.138.291-.278.583-.418.874Q1.224,2.677.554,4.08c-.168.353-.333.707-.5,1.059A.95.95,0,0,0,.5,6.411c.141.058.274.131.412.2l1.183.57L4.283,8.235Z" fill="#fff" />
            <path id="Path_49" data-name="Path 49" d="M1209.264,637.318a.249.249,0,0,0,.222-.151q.277-.584.553-1.167.326-.684.651-1.369l1.355-2.853c.078-.162.154-.326.233-.487a.219.219,0,0,0-.021-.25.257.257,0,0,0-.246-.1c-.5.063-1,.124-1.492.188-.424.054-.846.112-1.268.167-.162.021-.326.041-.489.058a.1.1,0,0,0-.093.071q-.515,1.074-1.03,2.146c-.09.188-.179.375-.272.562a.118.118,0,0,0,0,.128q.836,1.457,1.667,2.918A.26.26,0,0,0,1209.264,637.318Z" transform="translate(-1190.405 -622.149)" fill="#fff" />
        </g>
    </svg>
    ,
    'Home',
);

function AllRoomsBlock({ loading, heightMainRooms }) {

    return (
        <div style={{ position: 'relative' }}>
            <Stack direction="row" spacing={2} className='AllRoomBlock_list__header'>
                <HomeIcon fontSize="inhariet" component="div" style={{ margin: '5px', }} />
                <Typography variant="subtitle2" component="div" className={styled.titleCameras}>
                    Live House Cameras
                </Typography>
                <Button variant="outlined" size="small" className={styled.unlockBtn} startIcon={<LockOpenOutlinedIcon />}>
                    UNLOCK ALL
                </Button>
            </Stack>
            <Box style={{ maxHeight: `${heightMainRooms}px` }} className='SchrollBar AllRoomBlock_list_wrapper'>
                <List className='AllRoomBlock_list'>
                    {(loading && cameras.length === 0) &&
                        [...Array(8)].map((e, i) =>
                            <Skeleton variant="rectangular" sx={{ bgcolor: 'grey.850' }} width={110} height={120} style={{ margin: "4px", borderRadius: "4px" }} />
                        )
                    }
                    {cameras && cameras.length && cameras.map((camera) => {
                        return <RoomCard key={camera.id} camera2={camera} />
                    })}
                </List>
            </Box>
        </div>
    );
};
export default AllRoomsBlock;