import React from 'react';
import VideoBlock from '../VideoBlock/VideoBlock'
import ChatBlock from '../ChatBlock/ChatBlock'
import Grid from '@mui/material/Grid';
import AllRoomsBlock from '../AllRoomsBlock/AllRoomsBlock';


function MainStreamScreen() {

    const [heightMain, setHeightMain] = React.useState(0);

    function getHeight(arg) {
        setHeightMain(arg)
    }

    return (
        <Grid className='parendCont MainStreamScreen' container justifyContent="center" spacing={0} style={{ color: '#ffffff' }} sx={{ bgcolor: 'black' }}>
            <Grid item md={2.5} sx={{ display: { xs: 'none', md: 'block' } }}>
                <AllRoomsBlock heightMainRooms={heightMain}/>
            </Grid>
            <Grid className="MainStreamScreen_video" item md={7}>
                <VideoBlock getHeightChat={getHeight}/>
            </Grid>
            <Grid className="MainStreamScreen_grid MainStreamScreen_chat" item xs={11} md={2.5}>
                <ChatBlock heightMainChat={heightMain}/>
            </Grid>
        </Grid>
    );
}

export default MainStreamScreen;