import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Home from '../pages/Home/Home';

const mapStateToProps = state => {
    return {
        
    }
}

const session = null;
const socket = null

function Router() {

    return (
        <>
            <Routes>
                <Route path='/' element={<Home session={session} socket={socket} />} />
                <Route path='*' element={<Navigate to="/" />} />
            </Routes>
        </>
    );
}

export default connect(mapStateToProps)(Router);