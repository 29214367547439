import React from 'react';
import MainStreamScreen from '../../components/MainStreamScreen/MainStreamScreen';
import { Box } from '@mui/material';
import styles from './Home.module.css';
import Header from '../../components/Header/Header';
import background from "../../assets/images/background.jpg"

function Main({ session }) {

    const queryParams = new URLSearchParams(window.location.search);

    const handleRedirect = (e) => {
        e.preventDefault();
        if (queryParams) {
            const target = queryParams.get("target");
            if (target) {
                let location = "https://warehouse-x.io/" + target;
                queryParams.delete("target");
                window.location.href = location + "?" + queryParams.toString();
            }
            else {
                window.location.href = "https://warehouse-x.io/" + "?" + queryParams.toString();
            }
        }
        else {
            window.location.href = "https://warehouse-x.io"
        }
    }

    return (
        <Box onClick={handleRedirect} className={styles.background} style={{ backgroundImage: "url(" + background + ")", cursor: 'pointer' }}>
            <Header session={session} />
            <MainStreamScreen />
        </Box>
    );
}
export default (Main);