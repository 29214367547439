import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Grow, ListItem, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useMemo } from 'react';

const user = {id: 1};
const chatId = 1;

function Message({ message, avatars }) {

    const [checked, setChecked] = React.useState(false);
    const elem = useRef(null);
    
    let avatar = useMemo(() => {
        return random_item(avatars);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function random_item(items) {

        return items[Math.floor(Math.random() * items.length)];
    }
    useEffect(() => {
        if (message.message == '') {
            return;
        }
        setTimeout(() => {
            setChecked(true);
        }, 30);
    }, []);

    const [msgClasses, setMsgClasses] = React.useState('message_wrap');

    useEffect(() => {

        if (message.message == '') {
            return;
        }

        if (message && message.previousId && message.previousSameType) {
            setTimeout(() => {
                const el = document.getElementById('message_wrap_' + message.previousId);
                /*   if (message.type == 'tip') {
                      if ((!document.getElementById('message_' + message.id)) && isLoaded) {
                          setTimeout(() => {
                              pop(document.getElementById('message_' + message.id));
                          }, 550);
                      }
                  } */
                if (el) {
                    if (!document.getElementById('message_' + message.id)) {
                        el.insertAdjacentHTML('beforeend', '<div class="msg animate pop MuiBox-root css-0"  id="message_' + message.id + '" data-message="' + message.message + '" data-id="' + message.id + '">' + message.message + `
                        <p class="MuiTypography-root MuiTypography-body1 time css-ahj2mt-MuiTypography-root">`+ (chatId != 1 ? message.data.time : '') + `</p>
                        </div>`);
                    }
                }
            }, 250);
        }

        if (message.type == 'tip') {
            setMsgClasses(msgClasses + ' tip')
        } else if (message.type == 'warning') {
            setMsgClasses(msgClasses + ' warning')
        }
        if (message.vip) {
            setMsgClasses(msgClasses + ' vip')
        }
    }, []);


    return (
        message !== '' && message.message !== '' ?
            !(message !== null && message.isPrevious) || !message.previousSameType ?
                <><ListItem ref={elem} key={message && message.id} id={message && message.id} className={(message && message.data !== null && user.id !== null && message.data.userId === user.id) ? 'ChatListElem odd' : 'ChatListElem'} style={(user && user.role == "model" || user.role == 'admin') ? { cursor: 'pointer' } : {}}>
                    <Grow timeout={370} delay={200} direction={(message.data !== null && user.id !== null && message.data.userId === user.id) ? 'right' : 'left'} in={checked} mountOnEnter unmountOnExit>
                        <Box className='ChatListElem_wrap'>
                            {(message.data !== null && user.id !== null && message.data.userId !== user.id) &&
                                <Box className='ImgPlace' style={(message && message.data && message.data.role == 'model') ? { cursor: 'pointer' } : {}} >
                                    <Avatar className={(message !== null && message.vip) ? "img vip" : 'img'} src={avatar} alt="" />
                                </Box>
                            }
                            <Box
                                className={msgClasses}
                                id={'message_wrap_' + message.id}
                            >
                                <Box id={'message_' + message && message.id} className="msg" data-message={message.message} data-id={message && message.id}>
                                    {(message.data !== null && user.id !== null && message.data.userId !== user.id) &&
                                        <Typography className='username'>{message !== null && message.data !== null && message.data.name}</Typography>
                                    }
                                    {(message !== null) && message.message}
                                    <Typography className='time'>{(message.data !== null && message.data.time !== null) ? message.data.time : ''}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grow>
                </ListItem>
                </>
                :
                <></>
            :
            ''
    )
}

export default Message;