import * as React from "react";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import styles from './VideoBlock.module.css'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CloseIcon from '@mui/icons-material/Close';
import { Button, createSvgIcon, Dialog, Typography } from "@mui/material";
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import JWPlayer from '@jwplayer/jwplayer-react';
import ReactHlsPlayer from 'react-hls-player';

const HomeIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="22.001" height="15.291" viewBox="0 0 22.001 15.291">
        <g id="Group_56" data-name="Group 56" transform="translate(0.1 0.123)">
            <path id="Path_48" data-name="Path 48" d="M4.362,8.275a1.692,1.692,0,0,0,.618,2.212.1.1,0,0,1,.055.088q0,1.351,0,2.7c0,.011,0,.023,0,.044H-.1V15.04h.075q2.52,0,5.039,0a1.749,1.749,0,0,0,1.114-.366,1.546,1.546,0,0,0,.569-.936,2.325,2.325,0,0,0,.044-.453q.006-1.344,0-2.689a.113.113,0,0,1,.061-.113,1.671,1.671,0,0,0,.665-.735c.054.024.106.045.158.071q1.114.538,2.226,1.074l2.7,1.3,1.41.679a1.035,1.035,0,0,0,1.087-.158,1.194,1.194,0,0,0,.281-.383q.716-1.52,1.434-3.04c.257-.543.511-1.088.769-1.632a.952.952,0,0,0-.463-1.273c-.157-.062-.3-.143-.457-.215l-2.947-1.4L11.7,3.837,8.821,2.469,6.564,1.4Q5.124.712,3.686.029A1.154,1.154,0,0,0,3.08-.12.885.885,0,0,0,2.311.4c-.138.291-.278.583-.418.874Q1.224,2.677.554,4.08c-.168.353-.333.707-.5,1.059A.95.95,0,0,0,.5,6.411c.141.058.274.131.412.2l1.183.57L4.283,8.235Z" fill="#fff" />
            <path id="Path_49" data-name="Path 49" d="M1209.264,637.318a.249.249,0,0,0,.222-.151q.277-.584.553-1.167.326-.684.651-1.369l1.355-2.853c.078-.162.154-.326.233-.487a.219.219,0,0,0-.021-.25.257.257,0,0,0-.246-.1c-.5.063-1,.124-1.492.188-.424.054-.846.112-1.268.167-.162.021-.326.041-.489.058a.1.1,0,0,0-.093.071q-.515,1.074-1.03,2.146c-.09.188-.179.375-.272.562a.118.118,0,0,0,0,.128q.836,1.457,1.667,2.918A.26.26,0,0,0,1209.264,637.318Z" transform="translate(-1190.405 -622.149)" fill="#fff" />
        </g>
    </svg>
    ,
    'Home',
);

export default function VideoBlock({ getHeightChat }) {

    const mainVideoScreen = React.useRef()
    const [prevent, setPrevent] = React.useState(false);

    React.useEffect(() => {
        setPrevent(true)
    }, []);

    React.useEffect(() => {
        if (prevent)
            setPrevent(false)
    }, [prevent]);

    React.useEffect(() => {

        let newHeight;

        if (mainVideoScreen && mainVideoScreen.current) {
            var video = document.getElementById("main_video");
            newHeight = video.clientHeight;
        }

        setTimeout(() => {
            getSize();
        }, 30);
        setTimeout(() => {
            getSize();
        }, 150);
        setTimeout(() => {
            getSize();
        }, 350);
        setTimeout(() => {
            getSize();
        }, 850);

    }, []);

    React.useEffect(() => {
        window.addEventListener("resize", getSize);
    }, []);

    React.useEffect(() => {
        setTimeout(() => {
            const toggleElements = document.getElementsByClassName('jw-icon');
            for (let index = 0; index < toggleElements.length; index++) {
                const el = toggleElements[index];
                //var link = document.createElement('a');
                //link.innerHTML = el.outerHTML;
                //link.setAttribute('href', 'https://warehouse-x.io');
                //el.parentNode.insertBefore(link, el);
                el.remove();
            }
        }, 1000)

    }, []);

    const handleClickOpen = () => {
        document.getElementById("CamerasModal").style.display = 'block';
        document.getElementById("CamerasModal").style.opacity = '1';
    };

    const handleClose = () => {
        document.getElementById("CamerasModal").style.opacity = '0';
        document.getElementById("CamerasModal").style.display = 'none';
    };

    React.useEffect(() => {
        playVideo();
    }, []);


    function playVideo() {
        if (!mainVideoScreen || !mainVideoScreen.current)
            return;
        mainVideoScreen?.current?.play();
    }

    const hasWindow = typeof window !== 'undefined';
    const [screenWidth, setScreenWidth] = React.useState();
    let width;

    const getSize = () => {
        playVideo()
        var video = document.getElementById("main_video");
        if (!video)
            return;
        const newHeight = video.clientHeight;
        return getHeightChat(newHeight)
    };

    function getDimensions() {
        width = hasWindow ? window.innerWidth : null;
        //height = window.innerHeight;
        if (width)
            setScreenWidth(width);
    }

    React.useEffect(() => {
        getDimensions();
        window.addEventListener("resize", getDimensions);
        document.getElementById('main_video').play();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const configDefaults = { autostart: 'viewable' };

    const randomvideos = [
        "https://media.warehouse-x.io/videos/e2-1.mp4",
        "https://media.warehouse-x.io/videos/e2-2.mp4",
        "https://media.warehouse-x.io/videos/e2-3.mp4",
        "https://media.warehouse-x.io/videos/e2-4.mp4",
        "https://media.warehouse-x.io/videos/e2-5.mp4",
        "https://media.warehouse-x.io/videos/e2-6.mp4",
    ];

    let randomVideo = randomvideos[Math.floor(Math.random() * randomvideos.length)];

    return (
        <>
            <div className={styles.StreamBlock + ' video_container'}>

                <video id="main_video" ref={mainVideoScreen} width="100%" height="auto" autoplay loop muted playsinline didMountCallback={getSize}>
                    <source src={randomVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

                <Stack direction="row" spacing={2} className={styles.liveStatus} disabled>
                    <FiberManualRecordIcon className={styles.liveIcon + " transColor"} size="small" style={{ color: '#FF0000' }} /><span className="transColor">LIVE</span>
                    <span className={styles.liveStatus_room + " transColor"}>- &nbsp; Live Show Room</span>
                </Stack>

                <Stack className={styles.btnChangeCamera}>

                    <Button style={{ maxWidth: '35px', minWidth: '35px', color: "white" }}>
                        <ArrowBackIosNewIcon fontSize="small" />
                    </Button>
                    <Typography className={styles.btnChangeCameraName}>
                        Live Show Room
                    </Typography>
                    <Button style={{ maxWidth: '35px', minWidth: '35px', color: "white" }}>
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </Stack>

                {screenWidth < 900 &&
                    <>
                        <Button className="btnBack"><ArrowBackIosNewIcon style={{ color: "white" }} /></Button>
                        <Button variant="outlined" onClick={handleClickOpen} className={styles.btnCameras}>
                            <HomeIcon fontSize="inhariet" component="div" style={{ marginBottom: '5px', marginRight: '25px' }} /> <span style={{ textAlign: 'center', letterSpacing: '0px', color: '#FFFFFF', textShadow: '0px 0px 6px #000000A3', opacity: '1' }}>Live Show Room</span>
                        </Button>
                        <Dialog
                            fullScreen
                            id="CamerasModal"
                            open={true}
                            onClose={handleClose}
                            className={'ModalDialog'}
                            style={{ transition: 'all .3s', opacity: '0', display: 'none' }}
                            onLoad={() => document.body.style.overflow = 'unset'}
                        >
                            <Stack direction="row" spacing={2} className={styles.AllRoomBlock_list__header}>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                    style={{ width: 'fit-content', marginLeft: '15px', marginTop: '5px', position: 'absolute', right: '10px', top: '2px' }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Box className={styles.AllRoomBlock_modal__header}>
                                    <HomeIcon fontSize="inhariet" component="div" style={{ margin: '5px', }} />
                                    <Typography variant="subtitle2" component="div" className={styles.titleCameras}>
                                        Live House
                                    </Typography>
                                    <Button variant="outlined" size="small" className={styles.unlockBtn} startIcon={<LockOpenOutlinedIcon />}>
                                        UNLOCK ALL
                                    </Button>
                                </Box>
                            </Stack>
                        </Dialog>
                        <Button className='btnForward'><ArrowForwardIosIcon style={{ color: 'white' }} /></Button>
                    </>
                }

            </div>

        </>
    );
}
